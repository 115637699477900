import {
  Box,
  Card,
  CardBody,
  AspectRatio,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { useGetDateContentQuery } from "../../redux/api/contentSlice";
import MetaDecorator from "../../Components/Utils/MetaDecorator";
import metaData from "../../Data/metaInfo";

function OriginalsCard({ url, img, title, description }) {
  return (
    <Card
      bg={"transparent"}
      _hover={{ filter: "brightness(1)", transform: "scale(1.005)" }}
      filter={"brightness(0.8)"}
      maxH="320"
      maxW="600px"
    >
      <Link href={url} isExternal style={{ textDecoration: "none" }}>
        <CardBody textAlign="left">
          <AspectRatio maxH="318px" overflow={"hidden"} borderRadius={"md"}>
            <Image
              // maxH="300px"

              objectFit="cover"
              src={img}
              alt={description}
            />
          </AspectRatio>

          <Stack mt="6" spacing="3">
            <Heading size="md" color={"#eee"}>
              {title}
            </Heading>
            <Text fontSize={"12px"} color={"#aaa"} noOfLines={3}>
              {description}
            </Text>
          </Stack>
        </CardBody>
      </Link>
    </Card>
  );
}

const Discover = () => {
  // const {data, isLoading, isError } = useGetNewContentsQuery();
  const { data, isLoading } = useGetDateContentQuery();

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Spinner
          thickness="5px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <div>
      <>
        <Heading justifyContent={"center"} display={"flex"} color={"white"}>
          Content Of The Day
        </Heading>
        <Text justifyContent={"center"} display={"flex"} color={"white"}>
          Handpicked from across the web
        </Text>
        <Box maxW={"1100px"} mx={"auto"} justifyContent="center" display="flex">
          <MetaDecorator
            title={metaData.discoverPage.title}
            description={metaData.discoverPage.description}
          />

          <SimpleGrid minChildWidth="255px" spacing={8} mb={12} p={4}>
            {data?.map((item) => (
              <OriginalsCard
                key={item.id}
                url={item.src}
                img={item.imageSrc}
                title={item.title}
                description={item.description}
              />
            ))}
          </SimpleGrid>
        </Box>
      </>
    </div>
  );
};

export default Discover;
